import React, { useEffect, useState } from "react"
import GlobalStyles from "../components/layout/GlobalStyle"
import MetaDetails from "../components/seoComponents/MetaDetails/MetaDetails"
import { Breadcrumbs } from "../components/layout/breadcrumbs/Breadcrumbs"
import styled from "styled-components"
import { device } from "../utils/mediaQueries"
import rehypeReact from "rehype-react"
import BlogFeaturedImage from "../components/images/BlogFeaturedImage"
import { CvAd } from "../components/ads/CvAd"

const BlogPost = styled.div`
  padding: 0rem 9rem;
  background-color: white;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: left;
  object-position: left;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  @media ${device.tablet} {
    padding: 0.2rem;
  }
`

const Header1 = styled.h1`
  font-size: 2.2rem;
  text-align: center;
  padding: 1rem;
  @media ${device.tablet} {
    font-size: 1.4rem;
  }
`

const TocHeader = styled.h4`
  text-align: center;
  color: black;
`

const ToC = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  position: ${props => (props.fixed ? "fixed" : "relative")};
  top: 0px;
  font-size: 1rem;
  @media ${device.tablet} {
    margin: 1rem 0.2rem;
    position: relative;
    padding: 1rem 3rem;
    border: 1px solid #dbdbdb;
  }
`

const MainArea = styled.div`
  flex: 8;
  max-width: 100vw;
`

const LeftSideBar = styled.div`
  flex: 2;
  margin: 0px auto;
`
const RightSideBar = styled.div`
  flex: 1;
`

const TableLink = styled.a`
  padding: 0.2rem;
  text-decoration: none;
  margin-left: ${props => props.margin || "0rem"};
  font-size: ${props => props.size || "0rem"};
  line-height: 1.1rem;
  font-weight: ${props => (props.depth > 1 ? 300 : 900)};
  margin-bottom: 0.1rem;
`

const Container = styled.div`
  padding: 2rem 6rem;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  background-color: white;
  @media ${device.tablet} {
    flex-direction: column;
    margin: 0px;
    padding: 1rem;
    border-radius: 0px;
  }
`

const HeadingContainer = styled.div`
  margin: 0rem auto;
  max-width: 600px;
`

const FeaturedImageConainer = styled.div`
  margin: 1rem auto;
`
const FeaturedImage = styled(BlogFeaturedImage)`
  margin: 1rem auto;
`

const BodyContainer = styled.div`
  background-color: white;
`

// eslint-disable-next-line new-cap
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template = ({ data }) => {
  const [position, setPosition] = useState(false)

  const listenScrollEvent = e => {
    if (window.scrollY > 390) {
      setPosition(true)
      console.log(window.scrollY)
      console.log("SrollY True", position)
    }
    if (window.scrollY < 390) {
      console.log(window.scrollY)
      console.log("SrollY False", position)
      setPosition(false)
    }
  }

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", listenScrollEvent)
  })
  console.log("Data", data)
  return (
    <GlobalStyles blog>
      <MetaDetails
        title={data.markdownRemark.frontmatter.metaTitle}
        desc={data.markdownRemark.frontmatter.metaDesc}
      />
      <BodyContainer>
        <HeadingContainer>
          <Header1 color={"#a35dbc"}>
            {data.markdownRemark.frontmatter.title}
          </Header1>
          <Breadcrumbs />
        </HeadingContainer>
        <Container>
          <LeftSideBar>
            <ToC fixed={position}>
              <TocHeader>Sisällysluettelo</TocHeader>
              {data.markdownRemark.headings.map((h, i) => (
                <TableLink
                  key={i}
                  depth={h.depth}
                  // weight={`${800 - h.depth * 200}`}
                  size={`${1.2 - h.depth / 10}rem`}
                  margin={`${0.6 * h.depth}rem`}
                  href={`#${h.value
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace("?", "")}`}
                >
                  {} {h.value}
                </TableLink>
              ))}
            </ToC>
          </LeftSideBar>
          <MainArea>
            <FeaturedImageConainer>
              <FeaturedImage
                full
                imgName={data.markdownRemark.frontmatter.featuredImage}
              />
            </FeaturedImageConainer>
            <CvAd />
            <BlogPost>
              <div>{renderAst(data.markdownRemark.htmlAst)}</div>
            </BlogPost>
          </MainArea>
          <RightSideBar></RightSideBar>
        </Container>
      </BodyContainer>
    </GlobalStyles>
  )
}
export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        featuredImage
        path
        title
        metaDesc
        metaTitle
      }
      htmlAst
      headings {
        depth
        value
      }
      timeToRead
    }
  }
`

export default Template
